import { useState } from "react";

export function useColumns() {
    const [columns, setColumns] = useState([
        {
            id: 1,
            field_id: 46,
            label: 'Job #',
            width: 150,
            type: 'text',
            shown: true,
            align: 'start',
          },
          {
            id: 2,
            field_id: 175,
            label: 'Job Status',
            width: 150,
            type: 'text',
            shown: false,
            align: 'center'
          },
          {
            id: 3,
            field_id: 86,
            label: 'State',
            width: 150,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 4,
            field_id: 87,
            label: 'City',
            width: 200,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 5,
            field_id: 187,
            label: 'Scheduling Notes',
            width: 300,
            type: 'text',
            shown: false,
            align: 'center'
          },
          {
            id: 6,
            field_id: 50,
            label: 'Crew Notes',
            width: 300,
            type: 'text',
            shown: false,
            align: 'center'
          },
          {
            id: 7,
            field_id: 64,
            label: 'Price',
            width: 200,
            type: 'text',
            shown: false,
            align: 'center'
          },
          {
            id: 8,
            field_id: 176,
            label: 'ALTA Major Client',
            width: 300,
            type: 'text',
            shown: false,
            align: 'center'
          },
          {
            id: 9,
            field_id: 61,
            label: 'Report Type',
            width: 250,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 10,
            field_id: 55,
            label: 'Hard Dates',
            width: 100,
            type: 'boolean',
            shown: false,
            align: 'center'
          },
          {
            id: 11,
            field_id: 52,
            label: 'Revisit Reason',
            width: 200,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 12,
            field_id: 177,
            label: 'Blew Ref #',
            width: 200,
            type: 'text',
            shown: false,
            align: 'center'
          },
          {
            id: 13,
            field_id: 182,
            label: 'Project #',
            width: 200,
            type: 'text',
            shown: false,
            align: 'center'
          },
          {
            id: 14,
            field_id: 65,
            label: 'Acreage',
            width: 100,
            type: 'text',
            shown: false,
            align: 'center'
          },
          {
            id: 15,
            field_id: 183,
            label: 'Address',
            width: 350,
            type: 'text',
            shown: false,
            align: 'center'
          },
          {
            id: 16,
            field_id: 66,
            label: 'Due Date',
            width: 150,
            type: 'text',
            shown: false,
            align: 'center'
          },
          {
            id: 17,
            field_id: 179,
            label: 'Title',
            width: 100,
            type: 'boolean',
            shown: false,
            align: 'center'
          },
          {
            id: 18,
            field_id: 47,
            label: 'Start Date',
            width: 150,
            type: 'date',
            shown: true,
            align: 'center'
          },
          {
            id: 19,
            field_id: 48,
            label: 'End Date',
            width: 200,
            type: 'date',
            shown: true,
            align: 'center'
          },
          {
            id: 20,
            field_id: 60,
            label: 'Crew MNGR',
            width: 200,
            type: 'select',
            shown: true,
            align: 'center'
          },
          {
            id: 21,
            field_id: 189,
            label: 'Sr. Crew Manager',
            width: 200,
            type: 'select',
            shown: false,
            align: 'center'
          },
          {
            id: 22,
            field_id: 139,
            label: 'Priority Job',
            width: 100,
            type: 'boolean',
            shown: false,
            align: 'center'
          },
          {
            id: 23,
            field_id: 184,
            label: 'Possible Navis Job',
            width: 250,
            type: 'boolean',
            shown: false,
            align: 'center'
          },
          {
            id: 24,
            field_id: 185,
            label: 'Site Contact',
            width: 400,
            type: 'text',
            shown: false,
            align: 'center'
          },
          {
            id: 25,
            field_id: 128,
            label: 'Site Contact Contacted',
            width: 250,
            type: 'boolean',
            shown: false,
            align: 'center'
          },
          {
            id: 26,
            field_id: 129,
            label: 'Site Contact Replied',
            width: 200,
            type: 'boolean',
            shown: false,
            align: 'center'
          },
          {
            id: 27,
            field_id: 57,
            label: 'Crew Left Site',
            width: 200,
            type: 'boolean',
            shown: true,
            align: 'center'
          },
          {
            id: 28,
            field_id: 208,
            label: 'Crew - Total Days on Site',
            width: 200,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 29,
            field_id: 219,
            label: 'Hours/Days Confirmed',
            width: 200,
            type: 'boolean',
            shown: true,
            align: 'center'
          },
          {
            id: 30,
            field_id: 245,
            label: 'Budgeted Days',
            width: 150,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 31,
            field_id: 246,
            label: 'Budgeted Days Override',
            width: 175,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 32,
            field_id: 199,
            label: 'Service - Total Days on Site',
            width: 200,
            type: 'text',
            shown: true,
            align: 'center'
          },
          {
            id: 33,
            field_id: 248,
            label: 'Over Budget',
            width: 100,
            type: 'boolean',
            shown: true,
            align: 'center'
          },
    ])
    // The width is the sum of the widths of all the SHOWN columns
    const totalWidth = columns.reduce((acc, curr) => {
        if (curr.shown) {
            return acc + curr.width
        }
        return acc
    }, 0)
    return { columns, setColumns, totalWidth }
}