import { useState } from "react";

export function useFilters() {
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState([
        {
            column: 1,
            field_id: 46,
            value: "",
        },
        {
            column: 2,
            field_id: 175,
            value: "",
        },
        {
            column: 3,
            field_id: 86,
            value: "",
        },
        {
            column: 4,
            field_id: 87,
            value: "",
        },
        {
            column: 5,
            field_id: 187,
            value: "",
        },
        {
            column: 6,
            field_id: 50,
            value: "",
        },
        {
            column: 7,
            field_id: 64,
            value: "",
        },
        {
            column: 8,
            field_id: 176,
            value: "",
        },
        {
            column: 9,
            field_id: 61,
            value: "",
        },
        {
            column: 10,
            field_id: 55,
            value: "",
        },
        {
            column: 11,
            field_id: 52,
            value: "",
        },
        {
            column: 12,
            field_id: 177,
            value: "",
        },
        {
            column: 13,
            field_id: 182,
            value: "",
        },
        {
            column: 14,
            field_id: 65,
            value: "",
        },
        {
            column: 15,
            field_id: 183,
            value: "",
        },
        {
            column: 16,
            field_id: 66,
            value: "",
        },
        {
            column: 17,
            field_id: 179,
            value: "",
        },
        {
            column: 18,
            field_id: 47,
            value: "",
        },
        {
            column: 19,
            field_id: 48,
            value: "",
        },
        {
            column: 20,
            field_id: 60,
            value: "",
        },
        {
            column: 21,
            field_id: 189,
            value: "",
        },
        {
            column: 22,
            field_id: 139,
            value: "",
        },
        {
            column: 23,
            field_id: 184,
            value: "",
        },
        {
            column: 24,
            field_id: 185,
            value: "",
        },
        {
            column: 25,
            field_id: 128,
            value: "",
        },
        {
            column: 26,
            field_id: 129,
            value: "",
        },
        {
            column: 27,
            field_id: 57,
            value: "",
        },
        {
            column: 28,
            field_id: 208,
            value: "",
        },
        {
            column: 29,
            field_id: 219,
            value: "",
        },
        {
            column: 30,
            field_id: 245,
            value: "",
        },
        {
            column: 31,
            field_id: 246,
            value: "",
        },
        {
            column: 32,
            field_id: 199,
            value: "",
        },
        {
            column: 33,
            field_id: 248,
            value: "",
        },
    ]);

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }

    return {showFilters, toggleFilters, filters, setFilters};
}